/* @font-face {
  font-family: 'Biome';
  src: url('../fonts/Biome-W01-Regular-1.ttf') format('truetype');
} */

:root {
  scroll-behavior: smooth;
  --dark-blue: #0a121b;
  --white-color: #fff;
  --dark-color: #000;
  --light-dark-color: #141414;
  --medium-dark-color: #181818;
  --medium-gray-color: #7f7c7c;
}

body {
  font-family: 'Arial';
  width: 100%;
  margin: 0 auto;
}

#root {
  max-width: 100%;
  margin: 0 auto;
}

.cards-vehicle-list {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 98%;
  margin: 0 auto;
}

::selection {
  background-color: var(--dark-color);
  color: var(--white-color);
}

.vehicle-card {
  /* border: 1px solid #ddd; */
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 1px 1px 1px #0000002a;
  /* width: calc(33.33% - 20px); */
  margin: 0 auto;
  margin-bottom: 35px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.vehicle-card:hover {
  /* background-color: #f3f3f3; */
  transition: background-color 0.5s ease;
  box-shadow: 4px 4px 4px #00000042;
}

.vehicle-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  height: 220px;
}

.vehicle-details h2 {
  margin-top: 0;
  text-align: center;
}

.vehicle-details p {
  margin: 5px 0;
}

.vehicle-details {
  padding: 15px;
}

.banner {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.banner img {
  /* width: 100%; */
  /* height: auto; */
  display: block;
  margin: 0 auto;
}

.titleHome, .modelo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.titleHome {
  text-align: initial;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;

  font-size: 20px;
}

.titleHome.animate {
  text-align: initial;
  opacity: 1;
  transform: translateY(0);
}
 
.montadora {
  text-align: initial;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.8s ease;
}

.montadora.animate {
  text-align: initial;
  opacity: 1;
  transform: translateY(0);
}
 
.modelo {
  text-align: initial;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 1s ease;
}

.modelo.animate {
  text-align: initial;
  opacity: 1;
  transform: translateY(0);
}

.ano {
  text-align: initial;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 2s ease;
}

.ano.animate {
  text-align: initial;
  opacity: 1;
  transform: translateY(0);
}

.price.animate {
  text-align: initial;
  opacity: 1;
  transform: translateY(0);
}

.price {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 2s ease;
  color: var(--white-color);
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: unset;
  border-radius: 0 0 8px 8px;
  font-weight: bolder;
}


.vehicle-details.animate {
  text-align: initial;
  opacity: 1;
  transform: translateY(0);
}

.vehicle-details {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 2s ease;
}

p {
  font-size: 16px;
  color: #312e2eeb;
}

h2 {
  color: #181717;
  font-size: 30px;
  padding-top: 10px;
}

.color-transition-button {
  background: linear-gradient(to right, #060606ed 0%, #060606ed 50%, #141414 50%, #000000 100%);
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.5s ease, font-size 0.5s ease; 
  color: var(--white-color);
  border: none;
  cursor: pointer;
  /* border: 2px solid transparent; */
}

.color-transition-button:hover {
  background-position: 0 0;
  /* color: #181818; */
  /* border: 2px solid #312e2e; */
  font-size: 22px;
  /* transition: font-size 0.5s ease;  */
}

/* Define the transition for vehicle details */
.vehicle-details-enter {
  opacity: 0;
  transform: translateY(20px);
}
.vehicle-details-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 700ms, transform 700ms;
}


/* Style the carousel */
.carousel-root {
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

/* Customize carousel indicators and slides */
.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

li.slide div {
  border: 15px solid var(--white-color);
  box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0 0;
}

li.slide div img {
  border-radius: 20px 20px 0 0;
}

li.selected {
  margin-bottom: -2px !important;
}

li.thumb {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  height: 62.5px;
}

li.thumb:last-child {
  margin-right: 11px;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 2px solid #fff !important;
  transition: border 300ms;
}

.carousel {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.carousel .thumbs-wrapper {
  margin: unset !important;

}


.carousel .thumb img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.close-button {
  padding-top: 5px;
  padding-left: 5px;
}

.close-icon {
  cursor: pointer;
  width: 26px;
  padding: 7px 17px;
  transition: box-shadow 0.5s ease;
  border-radius: 8px;
}

.close-icon:hover{
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.vehicle-screen-detailed span {
  font-size: 15px;
  color: #312e2eeb;
  font-family: Arial, Helvetica, sans-serif;
}


.priceDetailed.animate {
  text-align: initial;
  opacity: 1;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 22px;

}

.priceDetailed {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 2s ease;
  color: #fff !important;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: unset;
  border-radius: 8px;
  padding: 5px 30px;
  font-weight: bolder;
  border: 1px solid #000000eb;
  box-shadow: 0 0 5px 5px #0000005e;
  transition: transform 0.2s ease;
}

.priceDetailed:hover {
  transition: transform 0.2s ease;
  transform: scale(1.1);
}

.slide {
  padding-top: 8px;
}

.slider-wrapper {
  height: 100% !important;
}

.vehicle-screen-detailed.animate {
  text-align: initial;
  opacity: 1;
  transform: translateY(0);
  padding-bottom: 150px;
}

.vehicle-screen-detailed {
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 2s ease;
}

.summaryDetailedParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  flex-flow: wrap;
}

.footer {
  font-family: Arial, Helvetica, sans-serif;
  background: #181818;
  color: var(--white-color);
  padding: 20px;

}

.footer p {
  font-family: Arial, Helvetica, sans-serif;
  color: var(--white-color);
  font-size: 16px;
}

.footer h1 {
  font-size: 20px;
}

.footer-parent {
  display: grid;
  grid-template-columns: 1fr 1fr 0;
  max-width: 1350px;
  margin: 0 auto;
  padding-top: 20px;
}

.location {
  margin-left: 15px;
}

.whoarewe {
  margin-right: 15px;
}

.copyright {
  font-size: 0.85rem;
  text-align: center;
  padding-top: 10px;
}

.dc {
  text-decoration: unset;
  font-weight: 800;
  color: var(--white-color);
}

.footer li, .contactTopUl li {
  list-style-type: none; /* This removes the default bullet points */
}

.footer ul {
  display: flex;
  gap: 20px;
  padding-inline-start: unset;
}

.contactTopUl {
  width: 60px;
}

.contactTopUl li {
  padding-left: 15px;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-content: center;
  align-self: center;
  align-items: center;
  color: var(--white-color);
  gap: 5px;
}

.contactTopUl .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-left: 5px; */
}

.liPhone {
  background: linear-gradient(144deg,
    transparent 0%, /* Top-left color */ 
    transparent 20%, /* Middle color */ 
    #a57a7a 70%, /* Bottom-right color */ 
    #ff5500 100% /* Bottom-right color */);
  height: 40px;
}

.liInstagram {
  background: linear-gradient(121deg, 
  /* transparent 0%,
  transparent 20%, */
  #833ab4 0%,
  #fd1d1d 70%,   /* Middle color (Red/Pink) */
  #fcb045 100%   /* Bottom-right color (Orange) */);
  height: 40px;
  border-radius: 0 0 5px 5px;
}

.contactTop ul {
  padding-inline-start: unset;
}
.liWhatsapp {
  background: linear-gradient(121deg,
  /* transparent 0%,
  transparent 20%, */
  #25D366 0%,
  #075E54 70%,   /* Middle color (Darker Green) */
  #128C7E 100%   /* Bottom-right color (Teal) */);
  height: 40px;
  border-radius: 5px 5px 0 0;
}

.imageLogo {
  height: 35px;
  padding-left: 0;
  padding-top: 5px;
  position: absolute;
}

.social-icons {
  display: flex;
  justify-content: start;
  align-items: center;
}

.liPhoneAlt {
  margin-left: 15px;
  font-weight: 600;
  font-size: 18px;
}

.iframeGmaps {
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 250px;
}

.divLottie {
  justify-content: center;
  padding-left: 15px;
  padding-top: 2px;
}

.lottiefile{
  width: 77px;
  padding-bottom: 15px;
}

.titleandLottie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  text-align: center;
}

.bannerOfPage {
  width: 100%;
  overflow: hidden;
}

.contactTop {
  right: 0;
  position: fixed;
  z-index: 5;
  padding-top: 8px;
}

.imageText, .logoImg {
  height: 100%;
}

.logo {
  position: absolute;
}

.footer {
  margin-top: 70px;
}

.vehicle-screen-detailed span {
  padding: 3px 0;
}

.summaryDetailed {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: 94.7px;
  /* max-width: 96px; */
  text-align: center;
  /* border-left: 1px solid #312e2eeb; */
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.summaryDetailed:hover {
  transition: transform 0.2s ease;
  transform: scale(1.1);
}

.titleDetailedTable {
  font-weight: 600;
  color: #312e2eeb;
}

.titleDetailed {
  text-align: center;
}

.carousel .thumb:hover {
  transition: border 0.2s ease;
}


.fabandmodel {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-flow: wrap;
  padding-top: 40px;
  padding-bottom: 20px;
}

.summaryDetailedModel {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  min-width: 230px;
  max-width: 250px;
  text-align: center;
  /* border-left: 1px solid #312e2eeb; */
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  height: 45px;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;
  padding-top: 3px;
  padding-bottom: 3px;
}

.summaryDetailedModel:hover {
  transition: transform 0.2s ease;
  transform: scale(1.1);
}

.summaryDetailedModel span {
  color: #312e2eeb;
}


.descriptionParent {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.descriptionsummaryDetailed {
  border-radius: 6px;
  display: flex;
  max-width: 670px;
  text-align: center;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: transform 0.2s ease;
  padding: 15px;
  text-align: start;
}

.descriptionsummaryDetailed:hover {
  transition: transform 0.2s ease;
  transform: scale(1.05);
}

.fetchOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffa1;
  z-index: 1000;
}

/* Mini mobile */
@media (max-width: 424px) {
  .logo {
    height: 52px;
    padding-top: 5px;
  }
  .logoImg {
    padding-left: 7px;
  }
  .rrMulti {
    height: 13px;
  }
  .vehicle-screen-detailed {
    padding: 5px;
  }
  .priceDetailed {
    margin-bottom: 40px;
    margin-top: 10px;
  }
  .carousel ul.thumbs {
    padding-inline-start: 0;
  }
  .footer-parent {
    display: flex;
    flex-direction: column;
  }
  .vehicle-screen-detailed.animate {
    padding-bottom: 0;
  }
  .footer {
    margin-top: 70px;
  }
  .modal h2 {
    text-align: center;
  }
  ul.thumbs {
    margin-top: 0;
  }
  li.slide div {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  .close-icon {
    padding: 0;
  }
  .location {
    margin-left: 0;
  }
  .whoarewe {
    margin-right: 0;
  }
  .fabandmodel {
    flex-flow: unset;
    gap: 10px;
    padding-top: 0;
  }
  .summaryDetailedModel {
    min-width: 50%;
    max-width: 50%;
  }
  .summaryDetailedModel span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h2 {
    margin: 0;
    padding-bottom: 10px;
  }
}
/* Mobile */
@media (min-width: 425px) and (max-width: 767px) {
  .logo {
    height: 52px;
    padding-top: 5px;
  }
  .logoImg {
    padding-left: 7px;
  }
  .rrMulti {
    height: 13px;
  }
  .priceDetailed {
    margin-bottom: 40px;
    margin-top: 10px;
  }
  .vehicle-screen-detailed {
    padding-top: 20px;
  }
  .carousel ul.thumbs {
    padding-inline-start: 0;
  }
  .footer-parent {
    display: flex;
    flex-direction: column;
  }
  .vehicle-screen-detailed.animate {
    padding-bottom: 36px;
  }
  .footer {
    margin-top: 50px;
  }
  .modal h2 {
    text-align: center;
  }
  li.slide div {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  .close-icon {
    padding: 0;
  }
  .location {
    margin-left: 0;
  }
  .whoarewe {
    margin-right: 0;
  }
  .fabandmodel {
    flex-flow: unset;
    gap: 10px;
    padding-top: 0;
  }
  .summaryDetailedModel {
    min-width: 50%;
    max-width: 50%;
  }
  .summaryDetailedModel span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h2 {
    margin: 0;
    padding-bottom: 10px;
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .logo {
    height: 103px;
    padding-top: 15px;
  }
  .logoImg {
    padding-left: 10px;
  }
  .rrMulti {
    height: 23px;
    padding-left: 8px;
  }
  .carousel ul.thumbs {
    padding-inline-start: 0;
  }
  .vehicle-screen-detailed.animate {
    /* max-width: 670px; */
    padding-bottom: 0;
    margin: 0 auto;
  }
  .carousel .thumbs-wrapper ul.thumbs {
    display: flex;
    flex-flow: wrap;
    max-width: 670px;
    justify-content: center;
    transform: unset !important;
  }
  
}

/* Laptop */
@media (min-width: 1024px) and (max-width: 1439px) {
  .logo {
    height: 103px;
    padding-top: 15px;
  }
  .logoImg {
    padding-left: 10px;
  }
  .rrMulti {
    height: 23px;
    padding-left: 8px;
  }
  .carousel ul.thumbs {
    padding-inline-start: 0;
  }
  .vehicle-screen-detailed.animate {
    /* max-width: 670px; */
    padding-bottom: 0;
    margin: 0 auto;
  }
  .carousel .thumbs-wrapper ul.thumbs {
    display: flex;
    flex-flow: wrap;
    max-width: 670px;
    justify-content: center;
    transform: unset !important;
  }
  
}

/* Big Screen */
@media (min-width: 1440px) and (max-width: 2000px) {
  .logo {
    height: 110px;
    padding-top: 20px;
  }
  .logoImg {
    padding-left: 10px;
  }
  .rrMulti {
    height: 23px;
    padding-left: 15px;
  }
  .carousel ul.thumbs {
    padding-inline-start: 0;
  }
  .vehicle-screen-detailed.animate {
    /* max-width: 670px; */
    padding-bottom: 0;
    margin: 0 auto;
  }
  .carousel .thumbs-wrapper ul.thumbs {
    display: flex;
    flex-flow: wrap;
    max-width: 670px;
    justify-content: center;
    transform: unset !important;
  }
  
}

/* Huge Screen */
@media (min-width: 2001px) {
  .logo {
    height: 206px;
    padding-top: 24px;
  }
  .logoImg {
    padding-left: 10px;
  }
  .rrMulti {
    height: 44px;
    padding-left: 17px;
  }
  .carousel ul.thumbs {
    padding-inline-start: 0;
  }
  .vehicle-screen-detailed.animate {
    /* max-width: 670px; */
    padding-bottom: 0;
    margin: 0 auto;
  }
  .carousel .thumbs-wrapper ul.thumbs {
    display: flex;
    flex-flow: wrap;
    max-width: 670px;
    justify-content: center;
    transform: unset !important;
  }
  
}
