.formContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 670px;
    margin: 0 auto;
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    border-radius: 6px;
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.contactParent {
    max-width: 670px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#submit {
    font-weight: 600;
    color: #fff;
    background: linear-gradient(to right, #060606ed 0%, #060606ed 50%, #141414 50%, #000000 100%);
    border: unset;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    cursor: pointer;
    transition: transform 0.2s ease;
    height: 45px;
    background-size: 200% 100%;
}

#submit:hover {
    background: #000;
    transition: transform 0.2s ease;
    transform: scale(1.1);
}


.checkboxGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contactParent input {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.checkboxGroup input {
    height: unset;
}

.contactParent div {
    padding-bottom: 15px;
}

textarea {
    height: 120px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 0;
}

#cellphone, #email, #name, #submit, textarea {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.43;
    padding: 5px;
}

#submit {
    width: 103%;
}

input[type="text" i], input[type="email" i], input[type="tel" i], input[type="file" i], input[type="image" i], input[type="checkbox" i], input[type="radio" i], input[type="submit" i] {
    padding: 0;
}

.modalOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1000; /* Ensure the overlay is on top of other content */
  }
  
  .successMessage {
    background-color: #041e04;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: 150px;
    padding-bottom: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .successMessage p {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    font-weight: 600;
  }

  .successMessage button {
    margin-top: 10px;
  }

  .policy {
    color: #fd1f1f;
    font-weight: 600;
    cursor: pointer;
  }

  .sendLottiefile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
  }

/* Mini mobile */
@media (max-width: 424px) {
    .formContact {
        width: 92%;
        margin: 0 auto;
        align-items: unset;
    }
    .contactParent {
        margin-left: -10px;
    }
  }
  /* Mobile */
  @media (min-width: 425px) and (max-width: 767px) {
    .formContact {
        width: 92%;
        margin: 0 auto;
        align-items: unset;
    }
    .contactParent {
        margin-left: -10px;
    }
  }
  
  /* Tablet */
  @media (min-width: 768px) and (max-width: 1023px) {
 
    
  }
  
  /* Laptop */
  @media (min-width: 1024px) and (max-width: 1439px) {
 
    
  }
  
  /* Big Screen */
  @media (min-width: 1440px) and (max-width: 2000px) {
 
    
  }
  
  /* Huge Screen */
  @media (min-width: 2001px) {
 
  }
  